export default defineNuxtPlugin(() => {
  const { isIntegrationEnabled, isProduction } = useUtils()
  if (!isIntegrationEnabled('affirm')) return

  const { $sitewideConfig, $uiEvents, $amplitude } = useNuxtApp()
  const route = useRoute()
  const { isDev } = useUtils()
  const urls = useUrls()
  const cartStore = useCartStore()
  const checkoutStore = useCheckoutStore()
  const notifications = useNotifications()

  const scriptSrc = isProduction()
    ? 'https://cdn1.affirm.com/js/v2/affirm.js'
    : 'https://cdn1-sandbox.affirm.com/js/v2/affirm.js'

  const _affirm_config = {
    public_api_key: $sitewideConfig.config.affirmApiKey,
    script: scriptSrc,
  }

  const loader = runOnDemand()

  loader.addCallback(() => {
    /* eslint-disable */
    // prettier-ignore
    // @ts-ignore
    // (function(l,g,m,e,a,f,b){var d,c=l[m]||{},h=document.createElement(f),n=document.getElementsByTagName(f)[0],k=function(a,b,c){return function(){a[b]._.push([c,arguments])}};c[e]=k(c,e,"set");d=c[e];c[a]={};c[a]._=[];d._=[];c[a][b]=k(c,a,b);a=0;for(b="set add save post open empty reset on off trigger ready setProduct".split(" ");a<b.length;a++)d[b[a]]=k(c,e,b[a]);a=0;for(b=["get","token","url","items"];a<b.length;a++)d[b[a]]=function(){};h.async=!0;h.src=g[f];n.parentNode.insertBefore(h,n);delete g[f];d(g);l[m]=c})(window,affirmConfig,"affirm","checkout","ui","script","ready");
    (function(m,g,n,d,a,e,h,c){var b=m[n]||{},k=document.createElement(e),p=document.getElementsByTagName(e)[0],l=function(a,b,c){return function(){a[b]._.push([c,arguments])}};b[d]=l(b,d,"set");var f=b[d];b[a]={};b[a]._=[];f._=[];b._=[];b[a][h]=l(b,a,h);b[c]=function(){b._.push([h,arguments])};a=0;for(c="set add save post open empty reset on off trigger ready setProduct".split(" ");a<c.length;a++)f[c[a]]=l(b,d,c[a]);a=0;for(c=["get","token","url","items"];a<c.length;a++)f[c[a]]=function(){};k.async=!0;k.src=g[e];p.parentNode.insertBefore(k,p);delete g[e];f(g);m[n]=b})(window,_affirm_config,"affirm","checkout","ui","script","ready","jsReady");
    /* eslint-enable */
  })

  // process affirm checkout token
  onNuxtReady(async () => {
    const affirmCheckoutToken = route.query.checkout_token?.toString()
    if (affirmCheckoutToken) processToken(affirmCheckoutToken)
  })

  async function processToken(token: string) {
    try {
      const orderId = await checkoutStore.processPayment({
        type: 'AFFIRM',
        token,
      })

      $uiEvents.$emit('affirmPaymentModalCompleted')

      await navigateTo(`/checkout/receipt/?orderId=${orderId}`)
    } catch (error) {
      if (error) {
        notifications.addError('Error', error.data?.msg || 'Affirm payment failed')
      }
    }
  }

  // Refresh affirm ui widgets
  let timer: any
  function refresh() {
    if (timer) clearTimeout(timer)

    timer = setTimeout(() => {
      if (window.affirm) window.affirm.ui.ready(() => window.affirm.ui.refresh())
    }, 500)
  }

  // Send user to the affirm checkout
  function open(successPath: string, cancelPath?: string) {
    loader.load()

    const domain = `https://${isDev() ? 'localhost:3000' : $sitewideConfig.domain}`

    const cartItems = cartStore.cart.items.map((item) => ({
      display_name: item.productLineName,
      sku: item.sku,
      unit_price: item.salePrice,
      qty: item.qty,
      item_image_url: item.image ? urls.getImageUrl(item.image.key, item.image.filename, 160, 120) : undefined,
      item_url: `${domain}/p/${item.productLineSlug}/`,
    }))

    const checkoutObj = {
      merchant: {
        user_confirmation_url: `${domain}${successPath}`,
        user_cancel_url: `${domain}${cancelPath ?? successPath}`,
        user_confirmation_url_action: 'GET',
        // Mike Tracy had a meeting with affirm and the accounts are properly set up now so we don't need to pass name anymore
        // name: $sitewideConfig.config.siteName,
      },
      shipping_amount: checkoutStore.checkout.summary.shippingSubtotal,
      tax_amount: checkoutStore.checkout.summary.taxSubtotal,
      total: checkoutStore.checkout.summary.grandTotal,
      items: cartItems,
      billing: {
        email: checkoutStore.checkout.billingContact?.email,
        phone: checkoutStore.checkout.billingContact?.phone,
        name: {
          full: checkoutStore.checkout.billingContact?.fullName,
        },
        address: {
          line1: checkoutStore.checkout.billingContact?.address1,
          city: checkoutStore.checkout.billingContact?.city,
          state: checkoutStore.checkout.billingContact?.stateId,
          zipcode: checkoutStore.checkout.billingContact?.zipcode,
          country: checkoutStore.checkout.billingContact?.country,
        },
      },
      shipping: {
        email: checkoutStore.checkout.shippingContact?.email,
        phone: checkoutStore.checkout.shippingContact?.phone,
        name: {
          full: checkoutStore.checkout.shippingContact?.fullName,
        },
        address: {
          line1: checkoutStore.checkout.shippingContact?.address1,
          city: checkoutStore.checkout.shippingContact?.city,
          state: checkoutStore.checkout.shippingContact?.stateId,
          zipcode: checkoutStore.checkout.shippingContact?.zipcode,
          country: checkoutStore.checkout.shippingContact?.country,
        },
      },
    }

    $uiEvents.$emit('affirmPaymentModalViewed')
    $uiEvents.$emit('affirmPaymentInfoEntered')

    window.affirm.checkout(checkoutObj)
    window.affirm.checkout.open()
  }

  function trackTest() {
    if (!isIntegrationEnabled('amplitude')) return
    $amplitude.track('affirm-product-tile')
  }

  async function isInAmplitudeTest() {
    if (!isIntegrationEnabled('amplitude')) return false
    const testId = 'affirm-product-tile'
    const variant = await $amplitude.getVariant(testId)
    return variant?.value === 'treatment'
  }

  return {
    provide: {
      affirm: {
        load: loader.load,
        refresh,
        open,
        trackTest,
        isInAmplitudeTest,
      },
    },
  }
})

import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  type ISignUpResult,
} from 'amazon-cognito-identity-js'

export default defineNuxtPlugin(() => {
  const { isIntegrationEnabled } = useUtils()
  if (!isIntegrationEnabled('cognito')) return

  const { $sitewideConfig, $overlay } = useNuxtApp()
  const cartStore = useCartStore()
  const notifications = useNotifications()

  // Callback to run upon successful login
  let loginCallback: (() => void) | undefined

  // Init UserPool
  const pool = new CognitoUserPool({
    UserPoolId: $sitewideConfig.config.cognitoUserPoolId,
    ClientId: $sitewideConfig.config.cognitoClientId,
  })

  // Reactive state for ui changes
  const state = useState('cognito', () => ({
    isLoggedIn: false,
    isEmployee: false,
    isSalesAgent: false,
  }))

  // This will either get the current user if we had one logged in or return null
  let user: CognitoUser | null = pool.getCurrentUser()

  // This will init the state vars
  _getUserSession()

  // Getters
  function isLoggedIn() {
    return state.value.isLoggedIn
  }

  function isEmployee() {
    return state.value.isEmployee
  }

  function isSalesAgent() {
    return state.value.isSalesAgent
  }

  // Public Functions
  // Opens the login modal
  async function openModal(loginCb: () => void) {
    // Add cb to the list of callbacks
    if (typeof loginCb === 'function') {
      // Init session to make sure we are valid
      await _getUserSession()

      // If we are logged in then we just want to run the callback that was supplied.
      if (isLoggedIn()) {
        await loginCb()
        return
      }

      loginCallback = loginCb
    }

    // Open login modal
    $overlay.open('cognitoLoginPopup')
  }

  // Close the login modal
  function closeModal() {
    loginCallback = undefined
    $overlay.close()
  }

  // Begin login flow
  async function login(email: string): Promise<string | undefined> {
    const formattedEmail = email.toLowerCase()
    try {
      // Attempt to init auth
      await _initAuth(formattedEmail)

      // if we succeed then we don't have to create any users
      return undefined
    } catch (error: any) {
      // if we find out the user isn't found then we need to make the user.
      if (error.code !== 'UserNotFoundException') throw new Error('login issue')
    }

    // Create the new user
    const resp = await _createUser(formattedEmail)
    if (!resp) throw new Error('Error creating account')

    // Then attempt to re init the auth
    await _initAuth(formattedEmail)

    return resp.userSub
  }

  // Complete the passwordless login via code from email
  function completeLogin(code: number) {
    return new Promise((resolve, reject) => {
      if (!user) {
        reject(new Error('user error'))
        return
      }

      user.sendCustomChallengeAnswer(code, {
        onSuccess: async () => {
          // If we successfully login and have a callback, run it
          if (loginCallback) {
            await loginCallback()
            loginCallback = undefined
          }

          // Attempt to close the modal
          closeModal()

          resolve(null)
        },
        onFailure: (err) => {
          reject(err)
        },
      })
    })
  }

  // Logout user
  function logout() {
    _logout()

    cartStore.restoreOriginalCart()

    notifications.addSuccess('Success', `You have been logged out.`)
  }

  // Get users access token
  async function getToken() {
    const session = await _getUserSession()

    if (!session) return null

    return session.getAccessToken().getJwtToken()
  }

  // Get user attributes like user id and email
  async function getUserAttributes() {
    const session = await _getUserSession()

    if (!session) return null

    const tokenPayload = session.getIdToken().decodePayload()

    return {
      id: tokenPayload.sub,
      email: tokenPayload.email,
    }
  }

  function getUsername() {
    if (!user) return ''
    return user.getUsername()
  }

  // Used to create users via segment
  async function generateUserId(email: string) {
    const response = await _createUser(email.toLowerCase()).catch(() => {})
    return response?.userSub || null
  }

  // === Private Functions === //
  function _initAuth(email: string) {
    return new Promise((resolve, reject) => {
      const authDetails = new AuthenticationDetails({
        Username: email,
      })

      user = new CognitoUser({
        Pool: pool,
        Username: email,
      })

      user.setAuthenticationFlowType('CUSTOM_AUTH')
      user.initiateAuth(authDetails, {
        onSuccess: () => {
          // User authentication was successful
          // Do nothing but it is Required to Make TS happy...
        },
        customChallenge: () => {
          // code was emailed to the user
          // get the challenge from the user
          resolve(null)
        },
        onFailure: (err) => {
          reject(err)
        },
      })
    })
  }

  function _createUser(email: string): Promise<ISignUpResult | undefined> {
    return new Promise((resolve, reject) => {
      if (!pool) {
        reject(new Error('Misconfigured pool'))
      }

      pool.signUp(email, _makeid(16), [], [], (err, data) => {
        if (err) {
          reject(err)
          return
        }

        resolve(data)
      })
    })
  }

  // Gets the current users session
  function _getUserSession() {
    return new Promise<CognitoUserSession | undefined>((resolve) => {
      if (!user) {
        _logout()
        return resolve(undefined)
      }

      user.getSession((err: Error | null, session: CognitoUserSession | null) => {
        // If we have an error, no session, or if the session is not valid
        if (err || !session?.isValid()) {
          _logout()

          return resolve(undefined)
        }

        // If we make it here then we are logged in.
        state.value.isLoggedIn = true

        // We need to check if this user is an employee
        const tokenPayload = session.getAccessToken().decodePayload()
        const groups = tokenPayload['cognito:groups'] || []

        const isSalesAgent = groups.includes('SALES_REP')

        state.value.isEmployee = isSalesAgent
        state.value.isSalesAgent = isSalesAgent

        return resolve(session)
      })
    })
  }

  function _logout() {
    if (user) {
      user.signOut()
      user = null
    }

    state.value.isLoggedIn = false
    state.value.isEmployee = false
    state.value.isSalesAgent = false
  }

  // Password generator functions
  function _makeid(length: number) {
    const result = []
    const upper = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    const lower = 'abcdefghijklmnopqrstuvwxyz'
    const number = '0123456789'
    const specials = '!$%^&#'
    const characters = upper + lower + number + specials
    const charactersLength = characters.length

    result.push(upper.charAt(Math.floor(Math.random() * upper.length)))
    result.push(lower.charAt(Math.floor(Math.random() * lower.length)))
    result.push(number.charAt(Math.floor(Math.random() * number.length)))
    result.push(specials.charAt(Math.floor(Math.random() * specials.length)))
    for (let i = 0; i < length - 4; i++) {
      result.push(characters.charAt(Math.floor(Math.random() * charactersLength)))
    }

    return _shuffle(result).join('')
  }

  function _shuffle(array: string[]) {
    let currentIndex = array.length
    let randomIndex

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex--

      // And swap it with the current element.
      const randomVal = array[randomIndex]
      const currentVal = array[currentIndex]
      array[currentIndex] = randomVal
      array[randomIndex] = currentVal
    }
    return array
  }

  return {
    provide: {
      cognito: {
        isLoggedIn,
        isEmployee,
        isSalesAgent,
        openModal,
        closeModal,
        login,
        completeLogin,
        logout,
        getToken,
        getUserAttributes,
        generateUserId,
        getUsername,
      },
    },
  }
})

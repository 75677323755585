<template lang="pug">
div
  div(class='relative group')
    div(
      ref='items'
      class='snap-mandatory snap-x lg:snap-none [&>*]:snap-start [&>*]:flex-none lg:[&>*]:snap-align-none flex flex-nowrap overflow-x-scroll lg:overflow-x-hidden overflow-y-hidden'
      :class='containerClass'
    )
      slot

    div(
      class='absolute w-full top-0 bottom-0 justify-between items-center pointer-events-none transition-opacity'
      :class='arrowModeClass'
    )
      button(
        v-if='canScroll'
        title='previous'
        class='py-6 px-2 pointer-events-auto'
        :class='arrowButtonClass.left'
        @click='scrollLeft'
      )
        img(v-if='arrowStyle === "THIN"' src='/images/carousel-arrow.svg' class='w-5' alt='Last image')
        img(v-else src='@/assets/chevronLeft.svg' class='w-6 h-6' alt='Last page')
      button(
        v-if='canScroll'
        title='next'
        class='py-6 px-2 pointer-events-auto'
        :class='arrowButtonClass.right'
        @click='scrollRight'
      )
        img(v-if='arrowStyle === "THIN"' src='/images/carousel-arrow.svg' class='w-5 rotate-180' alt='Next image')
        img(v-else src='@/assets/chevronRight.svg' class='w-6 h-6' alt='Next page')
</template>

<script setup lang="ts">
// TODOLATER: Per Brian/Josh convo see if we can have the large image on the pline page jump vs scroll to the right position - what is doing that, scroll to?
// When you click on any thumb that's not adjacent to the current it will jump, but if it's next/prev is scrolls -- inconsistent!
const items = ref<HTMLElement | null>(null)
const scrolledLeft = ref(true)
const scrolledRight = ref(false)
const canScroll = ref(true)

const {
  arrowMode = 'HOVER',
  isLooped = false,
  containerClass = '',
  scrollTo = 0,
  arrowStyle = 'DEFAULT',
} = defineProps<{
  arrowMode?: 'NONE' | 'HOVER' | 'ALWAYS'
  isLooped?: boolean
  containerClass?: string
  scrollTo?: number
  arrowStyle?: 'DEFAULT' | 'THIN'
}>()

const arrowModeClass = computed(() => {
  if (arrowMode === 'NONE') return 'hidden'
  else if (arrowMode === 'ALWAYS') return 'flex'

  // Default 'HOVER' state
  return 'hidden opacity-0 lg:flex lg:group-hover:opacity-100'
})

const arrowButtonClass = computed(() => {
  const baseCarouselClasses = 'bg-white text-black shadow-md md:shadow-none'
  return {
    left: [
      scrolledLeft.value && !isLooped ? 'opacity-25' : 'opacity-90',
      arrowStyle === 'DEFAULT' ? `rounded-r ${baseCarouselClasses}` : '',
    ],
    right: [
      scrolledRight.value && !isLooped ? 'opacity-25' : 'opacity-90',
      arrowStyle === 'DEFAULT' ? `rounded-l ${baseCarouselClasses}` : '',
    ],
  }
})

watch(
  () => scrollTo,
  (newIndex: number, oldIndex: number) => {
    const speed = Math.abs(oldIndex - newIndex) > 1 ? 0 : 500

    if (items.value) scroll(items.value.children[newIndex], speed)
  }
)

onMounted(() => {
  checkCanScroll()

  items.value?.addEventListener('scroll', () => {
    checkScrollPosition()
  })
})

function scrollLeft() {
  if (!isLooped && scrolledLeft.value) return

  if (!items.value || items.value.children.length === 0) return

  const children = items.value.children

  if (isLooped && items.value.scrollLeft === 0) {
    scroll(children[children.length - 1])
    return
  }

  const target = getScrollTarget(false)
  if (!target) return

  scroll(target)
}

function scrollRight() {
  if (!isLooped && scrolledRight.value) return

  if (!items.value || items.value.children.length === 0) return

  const children = items.value.children

  if (isLooped && items.value.scrollLeft + items.value.clientWidth === items.value.scrollWidth) {
    scroll(children[0])
    return
  }

  const target = getScrollTarget()
  if (!target) return

  scroll(target)
}
function scroll(target: Element, speed = 500) {
  const { $scrollTo } = useNuxtApp()
  if (!items.value) return

  const options = {
    container: items.value,
    x: true,
    y: false,
    force: true,
    easing: 'ease-in-out',
  }

  $scrollTo(target, speed, options)
}
// Checks right scroll by default
function getScrollTarget(scrollingRight = true) {
  let firstClipped

  if (!items.value) return

  for (let i = 0; i < items.value.children.length; i++) {
    const elementBoundingRect = items.value.children[i].getBoundingClientRect()
    const parentBoundingRect = items.value.children[i].offsetParent.getBoundingClientRect()

    if (scrollingRight && elementBoundingRect.right > parentBoundingRect.right) {
      firstClipped = items.value.children[i]
      break
    } else if (
      !scrollingRight &&
      elementBoundingRect.left <= parentBoundingRect.left &&
      elementBoundingRect.left >= parentBoundingRect.left - parentBoundingRect.width
    ) {
      firstClipped = items.value.children[i]
      break
    }
  }

  return firstClipped
}
function checkScrollPosition() {
  if (!items.value) return

  scrolledLeft.value = false
  scrolledRight.value = false

  // Scrolled all the way to the left
  if (items.value.scrollLeft === 0) {
    scrolledLeft.value = true
    return
  }

  // Scrolled all the way to the right
  if (items.value.scrollLeft === items.value.scrollWidth - (items.value.offsetParent?.clientWidth || 0)) {
    scrolledRight.value = true
  }
}
function checkCanScroll() {
  if (!items.value || items.value.children.length === 0) return

  const lastItem = items.value.lastElementChild

  if (!lastItem) return

  if (!lastItem.offsetParent) return

  const lastItemBounds = lastItem.getBoundingClientRect()
  const parentBounds = lastItem.offsetParent.getBoundingClientRect()

  canScroll.value = lastItemBounds.right > parentBounds.right
}
</script>

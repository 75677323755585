<template lang="pug">
header
  //- Tool bar for the storyblok editor
  div(v-if='$storyblok.isEditing.value && isHeaderStory' class='border-b mt-4 mb-4 space-x-2 uppercase font-bold relative z-[61]')
    div(
      class='inline-block py-2 px-4 border border-b-0 rounded-t cursor-pointer'
      :class='{ "text-white bg-info": !isSimple, "bg-white": isSimple }'
      @click='setIsSimple(false)'
    ) Standard

    div(
      class='inline-block py-2 px-4 border border-b-0 rounded-t cursor-pointer'
      :class='{ "text-white bg-info": isSimple, "bg-white": !isSimple }'
      @click='setIsSimple(true)'
    ) Simple

  HeaderPromoBar(v-if='!isSimpleMode')

  template(v-if='headerData')
    CmsStoryblok(v-if='isSimpleMode' :sections='headerData.headerSimple')
    CmsStoryblok(v-else :sections='headerData.headerStandard')

  HeaderNavbar(v-if='!isSimpleMode')
  HeaderNavbarMobile(v-if='$device.value.isSmall')

  FitmentModal
  CognitoModal(v-if='isIntegrationEnabled("cognito")')
  GeolocationModal(v-if='isIntegrationEnabled("geolocation")')
  OrderStatusLoginModal
  Overlay(unique-key='sc' title='Email Cart' :enable-dark-mode='true' :is-persistent='true')
    LazyCartSaveModal
</template>

<script setup lang="ts">
const { $sitewideConfig, $storyblok } = useNuxtApp()
const { isIntegrationEnabled } = useUtils()
const rootStore = useRootStore()
const isSimple = ref(false)

const { data: previewData } = await useAsyncData('header-data', async () => {
  if (!$storyblok.isPreview.value || $storyblok.contentType.value !== 'header') return null

  const preview = await $storyblok.getPreviewStory<HeaderStory>()
  return preview?.story.content ?? null
})

const headerData = computed(() => {
  return previewData.value || $sitewideConfig.header
})

const isSimpleMode = computed(() => {
  if ($storyblok.isEditing.value) return isSimple.value
  return rootStore.$state.isSimpleMode
})

const isHeaderStory = computed(() => {
  return $storyblok.contentType.value === 'header'
})

const setIsSimple = (bool: boolean) => {
  isSimple.value = bool
}

onMounted(async () => {
  // Add a listener to update the content when in editor mode
  $storyblok.addListener((story) => {
    if ($storyblok.isHeaderStory(story)) previewData.value = story.story.content
  })
})

provide('getUniversalLinkTarget', getUniversalLinkTarget)

function getUniversalLinkTarget(event: any) {
  let target = event.target
  // Elements like IMG that are inside an A tag will report the IMG not the A
  // .closest will look for the closest parent matching our selector
  if (event.target?.tagName !== 'A') target = event.target.closest('[data-element-type="universalLink"]')

  // Early out if we don't find an A tag of elementType universalLink
  // Early out if we don't have an href
  if (target?.dataset?.elementType !== 'universalLink') return

  return target
}
</script>

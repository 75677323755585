export default function (shippingData) {
  // Make sure we have a sameDay obj
  if (!shippingData?.sameDay) return {}

  const today = new Date()
  const cutoffTime = new Date(shippingData.sameDay.cutoffTime)
  const nextShipDate = new Date(shippingData.sameDay.nextShipDate)

  // Are we before the cutoff time
  if (today < cutoffTime) {
    const remainingMs = cutoffTime - today

    const hours = Math.floor(remainingMs / 1000 / 60 / 60)
    const minutes = Math.floor(remainingMs / 1000 / 60) % 60
    const seconds = Math.floor(remainingMs / 1000) % 60

    let hoursString = ''
    if (hours === 0) hoursString = ''
    else if (hours > 9) hoursString = `${hours}h`
    else hoursString = `0${hours}h`

    const orderText = `Ships ${shippingData.isEligibleFreeShipping ? 'FREE ' : ''}today if ordered within `

    return {
      orderText,
      hours: hoursString,
      minutes: minutes > 9 ? `${minutes}m` : `0${minutes}m`,
      seconds: seconds > 9 ? `${seconds}s` : `0${seconds}s`,
    }
  }

  // Amount of hours between now and the next ship date
  const remainingHours = Math.floor((nextShipDate - today) / 1000 / 60 / 60)

  if (remainingHours < 24) {
    const orderText = `Order today, ships ${shippingData.isEligibleFreeShipping ? 'FREE ' : ''}tomorrow`

    return {
      orderText,
    }
  }

  // Amount of days until we can ship
  // NOTE: this will do nothing right now, until the api supports sending same day while in a shipping holiday
  const remainingDays = remainingHours / 24

  if (remainingDays < 6) {
    const orderText = `Order today, ships ${shippingData.isEligibleFreeShipping ? 'FREE ' : ''}${getFullNameDay(
      nextShipDate.getDay()
    )}`

    return {
      orderText,
    }
  }

  return {}
}

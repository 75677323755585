export const useCartStore = defineStore('cart', () => {
  const { $sitewideConfig, $log, $uiEvents } = useNuxtApp()
  const rootStore = useRootStore()
  const { getApiUrl } = useUrls()

  const cartUrl = getApiUrl('cart')

  const activePermIdCookie = useCookie('activePermId')

  const headers = {
    'x-site': $sitewideConfig.sitePrefix,
  }

  const cart = ref<Cart>({
    orderId: '',
    expiresAt: '',
    itemCount: 0,
    id: '',
    updatedAt: '',
    lastRequestedAt: '',
    total: 0,
    shortId: '',
    createdAt: '',
    insideSale: false,
    items: [],
    hasShippingProtection: false,
    leadSource: '',
  })

  const lastItem = computed(() => {
    const items = [...cart.value.items].sort((a, b) => Date.parse(b.updatedAt) - Date.parse(a.updatedAt))
    // TODOLATER: Jake doesn't trust his code ...This is the old code... awkward....
    // Moved this to a getter since the api updates the updatedAt value. Might have to change back when dealing with installernet or anything else that uses it.
    // let [lastItem] = items.filter((itemData) => itemData.productId === item.productId)

    // lastItem = Object.assign({}, lastItem)
    // lastItem.qty = item.qty
    // lastItem.originalSubtotal = lastItem.originalPrice * item.qty
    // lastItem.saleSubtotal = lastItem.salePrice * item.qty

    // state.popupCart.lastItem = lastItem
    return items[0]
  })

  async function getCart() {
    try {
      const resp = await $fetch<Cart>(`${cartUrl}${rootStore.permId}`, {
        headers,
      })

      cart.value = resp
    } catch (error) {
      // This is a temporary fix. The actual fix is ticket UWP-12057
      if ($log) $log.error('CRITICAL ERROR: Cannot get cart', { error })
    }
  }

  async function clearCart(permId?: string) {
    await $fetch(`${cartUrl}${permId ?? rootStore.permId}`, {
      method: 'DELETE',
      headers,
    })

    await getCart()
  }

  async function addShippingProtection() {
    const resp: { code: string } = await $fetch(`${cartUrl}${rootStore.permId}/shipping-protection`, {
      method: 'POST',
      headers,
    })

    if (resp.code !== 'SUCCESS') throw new Error(`Failed to add shipping protection.  Code: ${resp.code}`)

    await getCart()
  }

  async function removeShippingProtection() {
    const resp: { code: string } = await $fetch(`${cartUrl}${rootStore.permId}/shipping-protection`, {
      method: 'DELETE',
      headers,
    })

    if (resp.code !== 'SUCCESS') throw new Error(`Failed to remove shipping protection.  Code: ${resp.code}`)

    await getCart()
  }

  interface AddItemRequest {
    productId: string
    qty: number
    fitmentData?: Fitment
    meta?: {
      notes: string
    }
  }

  async function addItem(itemReq: AddItemRequest | AddItemRequest[]) {
    const items = Array.isArray(itemReq) ? itemReq : [itemReq]
    const item = items.map((item) => {
      const { productId, qty, fitmentData, meta } = item

      return {
        productId,
        qty,
        meta,
        fitmentData: formatFitmentData(fitmentData),
      }
    })

    try {
      await $fetch(`${cartUrl}${rootStore.permId}/items`, {
        method: 'POST',
        headers,
        body: {
          productList: item,
        },
      })
      await getCart()
    } catch (error: any) {
      // Log the error to Segment
      $uiEvents.$emit('errorLog', { errorType: 'Add To Cart: ' + error?.data?.code })
      throw error
    }
  }

  function formatFitmentData(fitmentData?: Fitment) {
    if (!fitmentData) return

    return {
      year: fitmentData.year,
      makeName: fitmentData.make,
      modelName: fitmentData.model,
      bedName: fitmentData.bed,
      bodyName: fitmentData.body,
      engineName: fitmentData.engine,
    }
  }

  async function updateItemQty(itemId: number, qty: number) {
    await $fetch(`${cartUrl}${rootStore.permId}/items/${itemId}`, {
      method: 'PATCH',
      headers,
      body: {
        id: itemId,
        qty,
      },
    })
    await getCart()
  }

  async function removeItem(itemId: number) {
    // find the item being removed
    const product = cart.value.items.find((item) => {
      return item.itemId === itemId
    })

    await $fetch(`${cartUrl}${rootStore.permId}/items/${itemId}`, {
      method: 'DELETE',
      headers,
    })

    $uiEvents.$emit('removeFromCart', product)

    // Get a fresh copy of the cart because the remove response does not update itemIds
    await getCart()
  }

  async function saveCart(fullName: string, email: string) {
    await $fetch(`${cartUrl}${rootStore.permId}/share`, {
      method: 'POST',
      headers,
      body: {
        name: fullName,
        email,
        url: `https://${$sitewideConfig.domain}/restore-cart/`,
      },
    })
  }

  async function restoreCart(pid: string, force: boolean) {
    if (force) {
      try {
        await $fetch(`${cartUrl}${rootStore.permId}/abandonedcart`, {
          method: 'POST',
          headers,
          body: {
            shortId: pid,
          },
        })

        await getCart()
      } catch (error) {
        $log.error('Unable to restore abandoned cart', { error })
      }
    } else {
      try {
        await $fetch(`${cartUrl}${rootStore.permId}/restore`, {
          method: 'POST',
          headers,
          body: {
            tempPermId: pid,
          },
        })

        await getCart()
      } catch (error) {
        $log.error('Unable to restore cart', { error })
      }
    }
  }

  async function restoreOriginalCart(options: { refreshCart?: boolean } = {}) {
    const { refreshCart = true } = options

    // If we run this then we need to make sure the cookie is removed in all cases
    activePermIdCookie.value = null

    // If the permId is already set to the original then we have no reason to do this part
    if (rootStore.permId === rootStore.originalPermId) return

    rootStore.setPermId(rootStore.originalPermId)

    // In some situations we want to control when the getCart is called so we have an option to disable
    if (refreshCart) await getCart()
  }

  return {
    cart,
    lastItem,
    getCart,
    clearCart,
    addItem,
    updateItemQty,
    removeItem,
    saveCart,
    restoreCart,
    restoreOriginalCart,
    addShippingProtection,
    removeShippingProtection,
  }
})

interface Cart {
  orderId: string
  expiresAt: string
  itemCount: number
  id: string
  updatedAt: string
  lastRequestedAt: string
  total: number
  shortId: string
  createdAt: string
  insideSale: boolean
  items: CartItem[]
  hasShippingProtection: boolean
  leadSource: string
}

declare global {
  interface CartItem {
    lineId: string
    id: string
    itemId: number
    fitmentData?: {
      year: string
      makeName: string
      modelName: string
      bedName?: string
      bodyName?: string
      engineName?: string
      appNote?: string
    }
    discount?: {
      amount: number
      rateFormatted: string
      endDate: string
      discountType: string
      text: string
      enabled: string
      startDate: string
    }
    promo?: {
      heading: string
      endDate: string
      qty: number
      text: string
      discountId: string
      enabled: string
      url: string
      startDate: string
    }
    qty: number
    image?: {
      title: string
      key: string
      filename: string
    }
    productId: string
    originalPrice: number
    itemPrice: number
    info?: { key: string; value: string }[]
    meta?: { notes?: string }
    mpn?: string
    sku: string
    skuSlug: string
    productName: string
    productLineName: string
    salePrice: number
    updatedAt: string
    productLineId: number
    productLineSlug: string
    productSlug: string
    category: string
    isVirtualItem?: boolean
    originalSubtotal: number
    itemSubtotal: number
    saleSubtotal: number
    shipping: {
      estimatedDeliveryDate?: { min: string; max: string }
      isEligibleFreeShipping: boolean
      sameDay?: {
        cutoffTime: string
        startDayOfWeek: string
        endDayOfWeek: string
      }
    }
    hasInstallationGuide: boolean
    hasInstallationVideo: boolean
    installation?: {
      quoteId: string
      regularPrice: number
      sellPrice: number
      cost: number
      expirationDate: string
      zip: string
      subtotal: number
    }
    installationAvailable?: {
      id: string
      quoteId: string
      sellPrice: number
      expirationDate: string
      zip: string
      city: string
      state: string
    }
    warranty?: {
      termLength: number
      price: number
      id: string
      url: string
      imageUrl: string
      planType: string
      subtotal: number
    }
    guaranteedFit: 'PHONE' | 'SITE'
    // didn't get used so not typing it
    // warrantiesAvailable: {
    //   termLength: number
    //   price: number
    //   id: string
    //   url: string
    //   imageUrl: string
    //   planType: string
    // }[]
  }
}

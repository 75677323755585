<template lang="pug">
div(v-if='starList' class='inline-flex items-center')
  div(class='flex')
    template(v-for='(fullStars, index) in starList.fullStars' :key='index')
      img(src='/images/star-full.svg' alt='' class='w-4 h-4 mr-px')
    template(v-if='starList.halfStar')
      img(src='/images/star-half.svg' alt='' class='w-4 h-4 mr-px')
    template(v-for='(emptyStars, index) in starList.emptyStars' :key='index')
      img(src='/images/star-empty.svg' alt='' class='w-4 h-4 mr-px')
  div(v-if='showTotalReviews' class='text-xs text-gray-dark font-normal ml-1 pt-px') {{ totalReviews }}
</template>
<script>
export default {
  props: {
    averageRating: {
      type: Number,
      default: 0,
    },
    totalReviews: {
      type: Number,
      default: 0,
    },
    showTotalReviews: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    starList() {
      if (this.totalReviews === 0) return undefined

      let fullStars = parseInt(this.averageRating)
      let remainingStars = 5 - fullStars
      const decimal = this.averageRating - fullStars

      const obj = {}

      if (decimal >= 0.75) {
        fullStars++
        remainingStars--
      } else if (decimal > 0.25 && decimal < 0.75) {
        obj.halfStar = true
        remainingStars--
      }

      obj.fullStars = fullStars
      obj.emptyStars = remainingStars
      return obj
    },
  },
}
</script>

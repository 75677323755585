<template lang="pug">
div
  ClientOnly
    TransitionRoot(as='template' appear :unmount='unmount' :show='$overlay.activeOverlay.value === uniqueKey')
      Dialog(:key='uniqueKey' :unmount='unmount' class='fixed z-50 inset-0 my-8' @close='$overlay.close()')
        TransitionChild(as='template' :unmount='unmount' v-bind='fadeAnimation')
          div(class='fixed inset-0' style='background-color: rgba(0, 0, 0, 0.8)' aria-hidden='true' @click='$overlay.close()')
        TransitionChild(
          as='template'
          :unmount='unmount'
          v-bind='animationClasses'
          @after-enter='$overlay.isOpen(true)'
          @after-leave='closed'
        )
          div(class='fixed inset-0 overflow-y-auto' :class='[panelWidthClass]')
            div(class='flex min-h-full items-center justify-center' :class='{ "h-full": isPanelMode }')
              DialogPanel(
                aria-modal='true'
                tabindex='0'
                class='relative flex flex-col lg:rounded'
                :class='[modalWidthClass, { "h-full": isPanelMode, "w-[300px] my-8": !isPanelMode }, getBgColor]'
              )
                DialogTitle(
                  v-if='title || !!$slots["header"]'
                  class='m-0 flex-none min-h-[40px] flex justify-between items-center px-6 py-3 font-bold border-gray-light uppercase'
                  :class='[getHeaderBgColor, { "border-b text-xl m-0 text-inherit leading-inherit": isPanelMode, "rounded-t text-dark normal-case text-lg border-b-0": !isPanelMode }]'
                )
                  slot(name='header')
                    div(v-if='title' class='title m-0 leading-6')
                      | {{ title }}
                  button(:class='{ "absolute left-full top-0 ml-2": !isPanelMode }' @click='$overlay.close()')
                    CloseX(
                      aria-label='close'
                      class='w-4 h-4 cursor-pointer fill-current'
                      :class='{ "text-black": isPanelMode, "text-white stroke-current": !isPanelMode }'
                    )

                template(v-else)
                  div(class='absolute top-0' :class='{ "right-0 p-4": isPanelMode, "left-full ml-2": !isPanelMode }')
                    button(@click='$overlay.close()')
                      CloseX(
                        aria-label='close'
                        class='w-4 h-4 cursor-pointer fill-current'
                        :class='{ "text-white stroke-current": !isPanelMode }'
                      )

                DialogDescription(v-bind='$attrs' :class='{ "overflow-y-auto": isPanelMode }')
                  slot(name='noPadding')
                    div(class='p-5')
                      slot
                footer(
                  v-if='!!$slots["footer"]'
                  class='p-5 flex-none mt-auto lg:bg-transparent lg:border-t-0'
                  :class='[getBgColor, { "border-t border-gray-light": mode === "LIGHT" }]'
                )
                  slot(name='footer')
</template>

<script setup lang="ts">
import CloseX from '@/assets/x.svg?component'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle, DialogDescription } from '@headlessui/vue'

defineOptions({
  inheritAttrs: false,
})

const emit = defineEmits(['closed'])
const { $device, $overlay } = useNuxtApp()

const {
  uniqueKey,
  unmount = true, // Default overlay's to V-IF
  isPersistent = false,
  animationStyle, // Undefined means 'left' on Mobile and 'fade' on Desktop
  modalWidth = 'NORMAL',
  panelWidth = 'FULL',
  title = null,
  // PANEL_DARK = Modals: Light Mode / Panels: Dark Mode
  // DARK      = Modals: Dark Mode / Panels: Dark Mode
  // LIGHT     = Modals: Light Mode / Panels: Light Mode
  mode = 'PANEL_DARK',
} = defineProps<{
  uniqueKey: string
  // unmount: Set to False if you want to V-Show the overlay
  unmount?: boolean
  // isPersistent: Persistent modals will remain open until manually closed so long as the users Path does not change.
  // Ex: Builds Filter Menu
  isPersistent?: boolean
  // animationStyle: Setting animationStyle to any of these forces it for both Desktop and Mobile
  // If you do not set animationStyle it will fade on desktop and slide left on mobile
  animationStyle?: 'LEFT' | 'RIGHT' | 'FADE'
  modalWidth?: 'SMALL' | 'NORMAL' | 'WIDE'
  panelWidth?: 'HALF' | 'FULL'
  title?: string
  mode?: 'PANEL_DARK' | 'LIGHT' | 'DARK'
}>()

const lightColor = 'bg-white'
const darkColor = 'bg-gray-lighter'

// Manually set isOpen/isActive to false when the Overlay is removed from the DOM
// This prevents a bug that keeps isActive true when changing routes from a page where
// the Overlay only exists (i.e. the Return modal on the Order Status page)
onUnmounted(() => $overlay.isOpen(false))

onMounted(() => {
  $overlay.initOverlay(uniqueKey, isPersistent)
})

// Sets the color of the Body and Footer based on mode state
const getBgColor = computed(() => {
  switch (mode) {
    case 'PANEL_DARK':
      return isPanelMode.value ? darkColor : lightColor
    case 'LIGHT':
      return lightColor
    case 'DARK':
      return darkColor
  }
})

// Sets the color of the Header based on mode
const getHeaderBgColor = computed(() => {
  switch (mode) {
    case 'PANEL_DARK':
    case 'LIGHT':
      return isPanelMode.value ? lightColor : darkColor
    case 'DARK':
      return lightColor
  }
})

const isPanelMode = computed(() => {
  return animationStyle === 'LEFT' || animationStyle === 'RIGHT' || (!animationStyle && $device.value.isSmall)
})

const panelWidthClass = computed(() => {
  if (!isPanelMode.value) return
  return {
    'w-1/2 min-w-[280px]': panelWidth === 'HALF', // Ex: Mobile Menu
    'w-full': panelWidth === 'FULL', // Default
  }
})

// Alert our Parent Component that the overlay has closed and is off the screen
// Ex: Some components need to perform state cleanup once the modal is closed.
function closed() {
  emit('closed')
  $overlay.isOpen(false)
}

// If a Modal is used on mobile the default width is 300px
// but we need to override that for tablets and larger that support wider screens
// so we provide 3 different size categories for overlays
const modalWidthClass = computed(() => {
  if (isPanelMode.value) return 'w-full'
  return {
    'lg:w-[400px]': modalWidth === 'SMALL',
    'lg:w-[640px]': modalWidth === 'NORMAL',
    'lg:w-[700px]': modalWidth === 'WIDE',
  }
})

const fadeAnimation = {
  enter: 'transition-opacity ease-linear duration-300',
  'enter-from': 'opacity-0',
  'enter-to': 'opacity-100',
  leave: 'transition-opacity  ease-linear duration-300',
  'leave-from': 'opacity-100',
  'leave-to': 'opacity-0',
}

const animationClasses = computed(() => {
  // Default animationStyle to LEFT when on mobile
  if (isPanelMode.value) {
    // Slide LEFT or RIGHT
    const translateSlideClass = !animationStyle || animationStyle === 'LEFT' ? '-translate-x-full' : 'translate-x-full'
    return {
      enter: `transition-transform transform ease-in-out duration-300`,
      'enter-from': `${translateSlideClass}`,
      'enter-to': 'translate-x-0',
      leave: `transition-transform transform ease-in-out duration-300`,
      'leave-from': 'translate-x-0',
      'leave-to': `${translateSlideClass}`,
    }
  }

  // Fade
  return fadeAnimation
})
</script>

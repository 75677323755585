export default defineNuxtPlugin(() => {
  const { $sitewideConfig } = useNuxtApp()
  const { addSrc, load, addCallback } = runScriptOnDemand()
  let api: any = null

  addSrc(`https://staticw2.yotpo.com/${$sitewideConfig.config.yotpoApiKey}/widget.js`)

  function getApi() {
    if (!api) api = new window.Yotpo.API(window.yotpo)
    return api
  }

  async function hasCustomerPhotos(yotpoId: string) {
    try {
      const data = await $fetch(
        `https://api.yotpo.com/v1/widget/${$sitewideConfig.config.yotpoApiKey}/albums/product/${yotpoId}?page=1&per_page=3`
      )
      if (!data || !data.response || data.response.images.length < 3) return false
      return true
    } catch (error) {
      if (error && error.response) console.error('get customer photos error', error.data)
      return false
    }
  }

  return {
    provide: {
      yotpo: {
        getApi,
        load,
        addCallback,
        hasCustomerPhotos,
      },
    },
  }
})

<template lang="pug">
div(:class='spacingClasses')
  //- TODOLATER: Consider creating a Component for stuff like this
  div(v-if='$storyblok.isEditing.value' class='border-b mb-2 space-x-2 uppercase font-bold')
    div(
      class='inline-block py-2 px-4 border border-b-0 rounded-t cursor-pointer'
      :class='{ "text-white bg-info": !isSuccessful, "bg-white": isSuccessful }'
      @click='isSuccessful = false'
    ) Form
    div(
      class='inline-block py-2 px-4 border border-b-0 rounded-t cursor-pointer'
      :class='{ "text-white bg-info": isSuccessful, "bg-white": !isSuccessful }'
      @click='isSuccessful = true'
    ) Success

  template(v-if='isSuccessful')
    template(v-for='widget in data.successWidgets' :key='widget._uid')
      slot(:data='widget')

  BaseForm(v-else :id='data._uid' :name='`subscribeForm-${data._uid}`' @submit='save')
    template(v-for='widget in data.formWidgets' :key='widget._uid')
      div(
        v-if='widget.component === "subscribe-form-item"'
        v-bind='$storyblok.bindStoryblokBridgeData(widget)'
        :class='{ relative: $storyblok.isEditing.value }'
      )
        template(v-if='widget.type === "NAME"')
          BaseFormInput(
            :id='widget._uid'
            label='First Name'
            name='first_name'
            :validation='widget.isRequired === "true" ? "required" : ""'
          )
          BaseFormInput(
            :id='widget._uid'
            label='Last Name'
            name='last_name'
            :validation='widget.isRequired === "true" ? "required" : ""'
          )

        BaseFormInputEmail(
          v-else-if='widget.type === "EMAIL"'
          :id='widget._uid'
          v-model='payload.email'
          label='Email'
          name='email'
          :validation='widget.isRequired === "true" ? "required" : ""'
        )

        BaseFormInputPhone(
          v-else-if='widget.type === "PHONE"'
          :id='widget._uid'
          label='Phone'
          name='phone'
          :validation='widget.isRequired === "true" ? "required" : ""'
        )
        template(v-else-if='widget.type === "FITMENT"')
          p(v-if='isFitmentDirty && !isFitmentValid' class='text-danger mb-2') Valid fitment is required!

          FitmentForm(:show-optionals='false' form-name='subscribeWidget' @is-fitment-valid='setFitmentValidation')

        div(v-if='$storyblok.isEditing.value' class='absolute inset-0 z-50')

      slot(v-else :data='widget')

    BaseButton(
      type='submit'
      label='Save'
      size='MEDIUM'
      :is-waiting='isSubmitting'
      :is-disabled='$storyblok.isEditing.value'
      class='pt-2'
    ) Submit
</template>

<script setup lang="ts">
const { $storyblok, $uiEvents } = useNuxtApp()
const fitmentDisplayStore = useFitmentDisplayStore()

interface SubscribeFormWidget extends Widget {
  formWidgets: FormWidget[]
  successWidgets: Widget[]
  mobileSpacing: GapStrings
  tabletSpacing: GapStrings
  desktopSpacing: GapStrings
  formName: string
}

interface FormWidget extends Widget {
  type: string
  isRequired: string
}

const { data } = defineProps<{
  data: SubscribeFormWidget
}>()

const isSuccessful: Ref<boolean> = ref(false)
const isFitmentDirty: Ref<boolean> = ref(false)
const isFitmentValid: Ref<boolean> = ref(false)
const isSubmitting: Ref<boolean> = ref(false)

interface Payload {
  email: string
  firstName: string
  lastName: string
  phone: string
}
const payload: Ref<Payload> = ref({
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
})

const spacingClasses = computed(() => {
  return generateGapClasses(data.mobileSpacing, data.tabletSpacing, data.desktopSpacing)
})

const isFitmentRequired = computed(() => {
  const fitment = data.formWidgets.find((widget) => {
    return widget.type === 'FITMENT'
  })

  if (!fitment) return false
  return fitment.isRequired === 'true'
})

function setFitmentValidation(val: boolean) {
  isFitmentValid.value = val
}

async function save() {
  // Double click protection
  if (isSubmitting.value) return

  isSubmitting.value = true
  isFitmentDirty.value = true // We can now show an error if the fitment is not valid

  // Check if Fitment is required and provided
  if (isFitmentRequired.value && !isFitmentValid.value) {
    isSubmitting.value = false
    return
  }
  // If we have a full fitment, submit it
  // Note: We do not pass Fitment data, as that is handled by the normal fitment events.
  if (isFitmentValid.value) await fitmentDisplayStore.saveFitment()

  // Cleanup strings
  const name = `${payload.value.firstName} ${payload.value.lastName}`.trim()
  const email = payload.value.email.trim()
  const phone = payload.value.phone.trim()
  // Create object for Segment
  const eventData = {
    formName: data.formName,
    ...(email && { email }),
    ...(name && { name }),
    ...(phone && { phone }),
  }

  $uiEvents.$emit('newsletterFormSubmitted', eventData)
  $uiEvents.$emit('newsletterSubscribed', eventData)
  isSuccessful.value = true
  isSubmitting.value = false
}
</script>

interface ThirdPartyItem {
  productId: string
  qty: number
  meta: any
}

const salt = 'M3JkcGFydHljb250cm9sc3N1Y2sh' // 3rdpartycontrolssuck! -- really simple/dumb 'security'

export default defineNuxtPlugin(() => {
  const thirdPartyState = useState<ThirdPartyItem[] | null>('thirdparty', () => null)
  const { isProduction } = useUtils()

  const activePermIdCookie = useCookie('activePermId', {
    secure: isProduction(),
    expires: new Date(new Date().setFullYear(new Date().getFullYear() + 10)), // 10 years
    decode(value) {
      return value
    },
  })

  const cartStore = useCartStore()
  const rootStore = useRootStore()
  const route = useRoute()

  // Server only
  if (import.meta.server) {
    // If we have an activePermId cookie set the permId to it
    if (activePermIdCookie.value && isValidPermId(activePermIdCookie.value))
      rootStore.setPermId(activePermIdCookie.value)

    // If we have a third party state in the route then we need to set it so its hydrated on the client
    if (route.query['3rdParty'] && route.query.data) {
      const data = route.query.data as string
      if (data.includes(salt)) {
        thirdPartyState.value = JSON.parse(Buffer.from(data.replace(salt, ''), 'base64').toString('utf-8'))
      }
    }
  }

  onNuxtReady(async () => {
    // If we have a third party state we need to add it to the cart
    if (thirdPartyState.value) {
      // addItem sets the cart state so we don't need to fetch the cart here
      await cartStore.addItem(thirdPartyState.value)

      // Reset the third party state
      thirdPartyState.value = null

      return
    }

    if (route.meta.disableGlobalGetCart) return

    await cartStore.getCart()
  })
})

<template lang="pug">
div(class='relative cursor-pointer' @mouseenter='openMiniCart' @mouseleave='delayCloseMiniCart')
  //- Shade for storyblok editor to prevent clicks
  div(v-if='$storyblok.isEditing.value' class='absolute inset-0 z-50')

  UniversalLink(class='flex justify-end text-inherit' link='/checkout/cart/')
    CartOutline(class='h-6 w-6 lg:h-7 lg:w-7 fill-current' :class='iconColorClass')

    span(
      class='font-bold text-center absolute leading-none h-[18px] w-[18px] top-[-4px] right-[1px] lg:right-[2px]'
      :class='counterClass'
    ) {{ filteredCartItemCount }}

  LazyCartMini(v-if='showMiniCart' class='z-40 absolute top-full right-0 m-1' style='width: 560px' @close='closeMiniCart')
</template>

<script setup lang="ts">
import CartOutline from '@/assets/cartOutline.svg?component'

const { $device, $storyblok, $extend } = useNuxtApp()
const { isIntegrationEnabled } = useUtils()

interface CartIconWidget extends Widget {
  iconColor: string
  countColor: string
}

const { data } = defineProps<{
  data: CartIconWidget
}>()

const showMiniCart: Ref<boolean> = ref(false)
const delayedClose: Ref<number | NodeJS.Timeout | undefined> = ref(undefined)
const route = useRoute()
const cartStore = useCartStore()
const filteredCartItemCount = computed(() => {
  return !isIntegrationEnabled('extend') || !import.meta.client
    ? cartStore.cart.items.length
    : $extend.getCartItemsExcludingShippingProtection(cartStore.cart.items).length
})

const iconColorClass = computed(() => {
  return generateFontColorClass(data?.iconColor)
})

const counterClass = computed(() => {
  return [generateFontColorClass(data?.countColor), cartStore.cart.itemCount >= 10 ? 'text-sm' : '']
})

function openMiniCart() {
  if ($device.value.isSmall || !cartStore.cart.itemCount) return

  clearTimeout(delayedClose.value)

  if (showMiniCart.value) return
  showMiniCart.value = true
}

function closeMiniCart() {
  showMiniCart.value = false
}

function delayCloseMiniCart(e: any) {
  // The e.relatedTarget is an IE 11 fix. when you change an item qty IE 11 triggers mouseleave.
  // this will simply escape the function so the minicart won't close when changing the item qty

  if ($device.value.isSmall || !e.relatedTarget) return

  clearTimeout(delayedClose.value)
  if (!showMiniCart.value) return
  delayedClose.value = setTimeout(() => {
    closeMiniCart()
  }, 700)
}

// Close the minicart if we navigate
watch(
  () => route.fullPath,
  () => {
    closeMiniCart()
  }
)
</script>

<template lang="pug">
div(
  data-testid='fitmentButton'
  class='flex items-center justify-center py-2 px-4 cursor-pointer capitalize font-bold'
  @click='open'
)
  template(v-if='!fitmentStore.fitmentFormatted')
    div(class='w-12 h-9 mr-3 flex items-center justify-center')
      img(:src='iconSrc' class='w-full')
    span(v-if='!fitmentStore.fitmentFormatted' class='text-sm' data-testid='vehicleSelection') Select Your Vehicle

  template(v-else)
    div(class='flex flex-col leading-tight')
      span(class='text-sm' data-testid='fitmentSelection') {{ fitmentStore.fitmentFormatted }}
      span(v-if='fitmentStore.fitmentFormattedSecondary' class='text-xs') {{ fitmentStore.fitmentFormattedSecondary }}
    div(class='border-l border-solid border-current leading-tight font-normal text-xs ml-auto pl-3 py-1 lg:ml-3') Change
</template>

<script setup lang="ts">
const { $storyblok } = useNuxtApp()
const fitmentStore = useFitmentStore()
const { showFitmentModal } = useFitmentDisplayStore()
const props = defineProps<{ icon: Object }>()

const iconSrc = computed(() => {
  return $storyblok.formatSrc(props.icon?.filename)
})

function open() {
  if ($storyblok.isEditing.value && $storyblok.contentType.value === 'navbar') return
  showFitmentModal()
}
</script>

import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

export default defineNuxtPlugin((nuxtApp) => {
  const { $sitewideConfig } = useNuxtApp()
  const { isIntegrationEnabled } = useUtils()
  const appConfig = useAppConfig()

  if (import.meta.server || !isIntegrationEnabled('bugsnag'))
    return {
      provide: {
        log: {
          info(data: any, metaData?: any) {
            console.log(data, metaData)
          },
          warning(data: any, metaData?: any) {
            console.warn(data, metaData)
          },
          error(data: any, metaData?: any) {
            console.error(data, metaData)
          },
        },
      },
    }

  Bugsnag.start({
    apiKey: $sitewideConfig.config.bugsnagApiKey,
    plugins: [new BugsnagPluginVue()],
    appVersion: appConfig.buildId,
  })

  const plugin = Bugsnag.getPlugin('vue')
  if (plugin) nuxtApp.vueApp.use(plugin)

  function sendMessage(error: Error, severity: 'info' | 'warning' | 'error', metaData?: any) {
    Bugsnag.notify(error, (event) => {
      event.severity = severity
      if (metaData) event.addMetadata('Extra Info', metaData)
    })
  }

  return {
    provide: {
      log: {
        info(data: any, metaData?: any) {
          sendMessage(data, 'info', metaData)
        },
        warning(data: any, metaData?: any) {
          sendMessage(data, 'warning', metaData)
        },
        error(data: any, metaData?: any) {
          sendMessage(data, 'error', metaData)
        },
      },
    },
  }
})

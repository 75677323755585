import GeoProvider from '../geoProvider'

export default defineNuxtPlugin(() => {
  const geoProvider = new GeoProvider()

  if (import.meta.server) {
    const headers = useRequestHeaders()

    const headerLocation = {
      city: headers['cloudfront-viewer-city'],
      country: headers['cloudfront-viewer-country-name'],
      region: headers['cloudfront-viewer-country-region'],
      regionName: headers['cloudfront-viewer-country-region-name'],
      zipcode: headers['cloudfront-viewer-postal-code'],
      edgePostalCode: headers['cloudfront-viewer-postal-code'],
      latitude: headers['cloudfront-viewer-latitude'],
      longitude: headers['cloudfront-viewer-longitude'],
    }

    geoProvider.setGeoLocationByHeaders(headerLocation)

    const geoLocationV2Cookie = useCookie('geoLocationV2')
    const geoLocationOldCookie = useCookie('geoLocation')

    // if we have cookie geoLocation, set the location from the cookie
    if (geoLocationV2Cookie.value) geoProvider.setGeoLocationByCookie(geoLocationV2Cookie.value)

    // if they still have the old geoData cookie remove it
    if (geoLocationOldCookie.value) geoLocationOldCookie.value = null
  }

  return {
    provide: {
      geolocation: geoProvider,
    },
  }
})

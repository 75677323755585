<template lang="pug">
div(
  v-show='price > 5000'
  :data-page-type='pageType'
  :data-amount='price'
  :data-learnmore-show='learnMore'
  class='affirm-as-low-as text-gray-dark [&_.affirm-ala-price]:text-black [&_.affirm-ala-price]:font-extrabold [&_.affirm-ala-price]:text-lg'
)
</template>

<script setup lang="ts">
const { $affirm } = useNuxtApp()

const {
  price = 0,
  pageType = 'product',
  learnMore = true,
} = defineProps<{
  price: number
  pageType: 'product' | 'cart' | 'category'
  learnMore?: boolean
}>()

watch(
  () => price,
  async () => {
    await nextTick()
    $affirm.refresh()
  }
)

onMounted(() => {
  $affirm.load()
  $affirm.refresh()
})
</script>

<template lang="pug">
component(
  v-bind='buttonAttributes'
  :is='is'
  class='relative inline-flex justify-center text-center outline-none items-center whitespace-nowrap cursor-pointer font-bold uppercase border rounded focus:outline-none'
  :class='classObject'
)
  div(v-if='isWaiting' class='absolute inset-0 flex justify-center items-center')
    div(
      class='after:relative after:block after:w-4 after:h-4 after:border-2 after:border-solid after:border-current after:rounded-full after:border-b-white after:border-r-transparent after:border-t-transparent pointer-events-none currentColor animate-spin'
    )

  //- We hide the content to preserve the width of the button when the spinner is active
  div(class='flex justify-center items-center' :class='{ "opacity-0": isWaiting }')
    Padlock(v-if='icon === "PADLOCK"' class='h-4 fill-current mr-2')
    Save(v-if='icon === "SAVE"' class='w-4 h-4 fill-current mr-2')
    slot
      | Submit

    Arrow(v-if='icon === "ARROW"' class='w-5 h-5 fill-current ml-2')
</template>

<script setup lang="ts">
import Padlock from '@/assets/padlock.svg?component'
import Save from '@/assets/save.svg?component'
import Arrow from '@/assets/arrow-right.svg?component'
import { UniversalLink } from '#components'

const {
  to,
  type = 'button',
  buttonStyle = 'SOLID',
  isDarkText = false,
  hasCustomTextColor = false,
  hasCustomPadding = false,
  hasCustomBackgroundColor = false,
  size = 'NORMAL',
  isInline = false,
  isWaiting = false,
  isDisabled = false,
  icon,
  color = 'ACTION',
} = defineProps<{
  to?: string // We want to use UniversalLink, so only strings
  type?: 'button' | 'submit' | 'reset'
  buttonStyle?: 'SOLID' | 'OUTLINE'
  isDarkText?: boolean // only applies to Solid Buttons
  hasCustomTextColor?: boolean // stops button from adding text classes so we can add a custom text color
  hasCustomPadding?: boolean
  hasCustomBackgroundColor?: boolean
  isInline?: boolean // If rendered inline with a form element
  isWaiting?: boolean // Determines if the Spinner is showing
  isDisabled?: boolean // Disabled buttons override color but obey style and may not be clicked on.
  size?: 'XSMALL' | 'SMALL' | 'MEDIUM' | 'NORMAL' | 'LARGE' // Determines size constraints/padding for the button
  icon?: 'PADLOCK' | 'SAVE' | 'ARROW' // An icon to display with the button (if any)
  color?: ButtonColor
}>()

const classObject = computed(() => {
  const colors = setColors()
  const padding = setPadding()
  return [
    colors,
    padding,
    {
      'hover:no-underline': to,
      'rounded-l-none': isInline,
      'pointer-events-none': isDisabled,
    },
  ]
})

const is = computed(() => {
  return !to ? 'button' : UniversalLink
})

const buttonAttributes = computed(() => {
  const attributes: any = {
    type: type,
  }

  if (to) {
    attributes.link = to // universal link now needs 'link', kept 'to' in this button since it's common
    delete attributes.type
  } else if (isDisabled) attributes.disabled = true // only apply disabled to buttons

  return attributes
})

function setColors() {
  if (isDisabled) {
    return 'bg-gray-lighter border-gray-lighter text-gray-light visited:text-gray-light'
  }

  let background = ''
  let border = ''
  // isDarkText only applies to Solid Buttons
  // Outline button text will be set to match color
  let text = isDarkText ? 'text-dark' : 'text-light'

  switch (color) {
    case 'ACTION':
      if (buttonStyle === 'SOLID') background = 'bg-action hover:bg-action-dark'
      // Else if OUTLINE
      else text = 'text-action visited:text-action'
      border = 'border-action hover:border-action-dark'
      break
    case 'PRIMARY':
      if (buttonStyle === 'SOLID') background = 'bg-primary hover:bg-primary-dark'
      // Else if OUTLINE
      else text = 'text-primary visited:text-primary'
      border = 'border-primary hover:border-primary-dark'
      break
    case 'PRIMARY_DARK':
      if (buttonStyle === 'SOLID') background = 'bg-primary-dark hover:bg-primary'
      // Else if OUTLINE
      else text = 'text-primary-dark visited:text-primary-dark'
      border = 'border-primary-dark hover:border-primary'
      break
    case 'SECONDARY':
      if (buttonStyle === 'SOLID') background = 'bg-secondary hover:bg-secondary-dark'
      // Else if OUTLINE
      else text = 'text-secondary visited:text-secondary'
      border = 'border-secondary hover:border-secondary-dark'
      break
    case 'INFO':
      if (buttonStyle === 'SOLID') background = 'bg-info hover:bg-info-dark'
      // Else if OUTLINE
      else text = 'text-info visited:text-info'
      border = 'border-info hover:border-info-dark'
      break
    case 'SUCCESS':
      if (buttonStyle === 'SOLID') background = 'bg-success hover:bg-success-dark'
      // Else if OUTLINE
      else text = 'text-success visited:text-success'
      border = 'border-success hover:border-success-dark'
      break
    case 'WARNING':
      if (buttonStyle === 'SOLID') background = 'bg-warning hover:bg-warning-dark'
      // Else if OUTLINE
      else text = 'text-warning visited:text-warning'
      border = 'border-warning hover:border-warning-dark'
      break
    case 'DANGER':
      if (buttonStyle === 'SOLID') background = 'bg-danger hover:bg-danger-dark'
      // Else if OUTLINE
      else text = 'text-danger visited:text-danger'
      border = 'border-danger hover:border-danger-dark'
      break
    case 'GRAY':
      if (buttonStyle === 'SOLID') background = 'bg-gray hover:bg-gray-dark'
      // Else if OUTLINE
      else text = 'text-gray visited:text-gray'
      border = 'border-gray hover:border-gray-dark'
      break
    case 'GRAY_LIGHT':
      if (buttonStyle === 'SOLID') background = 'bg-gray-light hover:bg-gray'
      // Else if OUTLINE
      else text = 'text-gray-light visited:text-gray-light'
      border = 'border-gray-light hover:border-gray'
      break
    case 'GRAY_DARKEST':
      if (buttonStyle === 'SOLID') background = 'bg-gray-darkest hover:bg-black'
      // Else if OUTLINE
      else text = 'text-gray-darkest visited:text-gray-darkest'
      border = 'border-gray-darkest hover:border-black'
      break
    case 'WHITE':
      if (buttonStyle === 'SOLID') {
        text = 'text-dark visited:text-dark'
        background = 'bg-white border-white'
      } else text = 'text-white visited:text-white' // Else if OUTLINE
      border = 'border-white hover:border-white'
      break
    case 'BLACK':
      if (buttonStyle === 'SOLID') {
        text = 'text-light visited:text-light'
        background = 'bg-black border-black'
      } else text = 'text-black visited:text-black' // Else if OUTLINE
      border = 'border-black hover:border-black'
      break
  }

  if (hasCustomTextColor) text = ''
  if (hasCustomBackgroundColor) {
    background = ''
    border = ''
  }

  return background + ' ' + border + ' ' + text
}

function setPadding() {
  let padding = ''
  let fontSize = ''

  switch (size) {
    case 'XSMALL':
      padding = 'py-1 px-3'
      fontSize = 'text-xs'
      break
    case 'SMALL':
      padding = 'py-3 px-6'
      fontSize = 'text-xs'
      break
    case 'NORMAL':
      padding = 'py-3 px-8'
      break
    case 'MEDIUM':
      padding = 'py-3 px-8'
      fontSize = 'text-xl'
      break
    case 'LARGE':
      padding = 'py-4 px-12'
      fontSize = 'text-xl'
      break
  }

  if (hasCustomPadding) padding = ''

  return padding + ' ' + fontSize
}

declare global {
  type ButtonColor =
    | 'ACTION'
    | 'INFO'
    | 'PRIMARY'
    | 'PRIMARY_DARK'
    | 'SECONDARY'
    | 'SUCCESS'
    | 'WARNING'
    | 'DANGER'
    | 'GRAY_LIGHT'
    | 'GRAY'
    | 'GRAY_DARKEST'
    | 'WHITE'
    | 'BLACK'
}
</script>

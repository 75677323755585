export default defineNuxtPlugin(() => {
  const { isIntegrationEnabled } = useUtils()
  if (!isIntegrationEnabled('ella')) return

  const router = useRouter()
  const { $sitewideConfig } = useNuxtApp()
  const key = $sitewideConfig.config.ellaKey
  const { load, addCallback } = runOnDemand()

  router.afterEach((to) => {
    if (to.path === '/careers/') load()
  })

  addCallback(() => {
    /* eslint-disable */
    // prettier-ignore
    window.oliviaChatData = window.oliviaChatData || []
    window.oliviaChatBaseUrl = 'https://olivia.paradox.ai'
    window.oliviaChatData.push(['setKey', key])
    window.oliviaChatData.push(['start'])
    ;(function () {
      var apply = document.createElement('script')
      apply.type = 'text/javascript'
      apply.async = true
      apply.src = 'https://dokumfe7mps0i.cloudfront.net/static/site/js/widget-client.js'
      var s = document.getElementsByTagName('script')[0]
      s.parentNode?.insertBefore(apply, s)
    })()
  })
})

declare global {
  interface Window {
    oliviaChatData: any[]
    oliviaChatBaseUrl: string
  }
}

export default defineNuxtPlugin(() => {
  const route = useRoute()
  const { $sitewideConfig, $device } = useNuxtApp()
  const { isDev } = useUtils()

  interface State {
    isEditing: boolean
    firstBgImageUrl: string
    tags: string[]
    authors: Author[]
    editors: Author[]
    published_at: string
    first_published_at: string
    selectors: Record<
      string,
      {
        mobile: boolean
        tablet: boolean
        desktop: boolean
      }
    >[]
  }

  const storyblokState = useState(
    'storyblok',
    (): State => ({
      isEditing: false,
      firstBgImageUrl: '',
      authors: [],
      editors: [],
      published_at: '',
      first_published_at: '',
      tags: [],
      selectors: [],
    })
  )

  function initState(story: Story) {
    // Type Specific State
    if (isCmsStory(story)) {
      storyblokState.value.authors = story.story?.content?.authors || []
      storyblokState.value.editors = story.story?.content?.editors || []
    }

    // Universal State
    const data = story.story
    storyblokState.value.tags = data.tag_list
    storyblokState.value.published_at = data.published_at
    storyblokState.value.first_published_at = data.first_published_at
  }

  function setFirstBgImageUrl(url: string) {
    if (storyblokState.value.firstBgImageUrl) return
    storyblokState.value.firstBgImageUrl = url
  }

  function getFirstBgImageUrl() {
    return storyblokState.value.firstBgImageUrl
  }

  function getTags() {
    return storyblokState.value.tags
  }

  function getPublishedAt() {
    return storyblokState.value.published_at
  }

  function getFirstPublishedAt() {
    return storyblokState.value.first_published_at
  }

  function getAuthors() {
    return storyblokState.value.authors
  }

  function getEditors() {
    return storyblokState.value.editors
  }

  async function getPreviewStory<T extends Story>() {
    try {
      // get storyId from the route query
      const storyId = route.query._storyblok

      // If we don't have a story id then we are not in preview/edit mode
      if (!storyId) return

      // If no data then we should fetch the data from the storyblok api
      const data = await $fetch<T>(
        `https://api.storyblok.com/v2/cdn/stories/${storyId}?token=${$sitewideConfig.config.storyblokApiKey}&version=draft`
      )

      if (!data?.story) return

      return data
    } catch (error) {
      console.log('unable to fetch story', error)
    }
  }

  // Are we viewing a preview of the story
  const isPreview = computed(() => {
    return !!route.query._storyblok
  })

  // Are we inside the storyblok editor
  const isEditing = computed(() => {
    return storyblokState?.value?.isEditing
  })

  // The Content Type we are currently looking at
  const contentType = computed(() => {
    return route.query._storyblok_c
  })

  function handleInheritedBool(mobile: StringBoolean, tablet: StringBoolean, desktop: StringBoolean) {
    const m = mobile !== 'INHERIT' ? mobile === 'true' : true
    const t = tablet !== 'INHERIT' ? tablet === 'true' : m
    const d = desktop !== 'INHERIT' ? desktop === 'true' : t

    return [m, t, d]
  }

  function isVisible(item: Widget) {
    // Widgets that do not have the visibilty properties in their schema will always be visible
    if (!item.mobileIsVisible || !item.tabletIsVisible || !item.desktopIsVisible) return true

    // Handle cascade of Inherit Rules
    const [mobileIsVisible, tabletIsVisible, desktopIsVisible] = handleInheritedBool(
      item.mobileIsVisible,
      item.tabletIsVisible,
      item.desktopIsVisible
    )
    if ($device.value.isMobile) return storyblokState.value.selectors[item.selector]?.mobile ?? mobileIsVisible
    if ($device.value.isSmall) return storyblokState.value.selectors[item.selector]?.tablet ?? tabletIsVisible
    return storyblokState.value.selectors[item.selector]?.desktop ?? desktopIsVisible
  }

  function visibleToggle(selector: string, deviceArgs: string[], displayValue: boolean) {
    if (!selector) return
    const validDevices = ['mobile', 'tablet', 'desktop']
    const targetDevices = deviceArgs.filter((deviceArg: string) => validDevices.includes(deviceArg))

    const devices = targetDevices.length ? targetDevices : validDevices

    const values = devices.reduce((acc: Record<string, boolean>, value: string) => {
      acc[value] = displayValue
      return acc
    }, {})

    return values
  }

  function show(selector: string, ...deviceKeys: string[]) {
    const values = visibleToggle(selector, deviceKeys, true)
    storyblokState.value.selectors[selector] = values
  }

  function hide(selector: string, ...deviceKeys: string[]) {
    const values = visibleToggle(selector, deviceKeys, false)
    storyblokState.value.selectors[selector] = values
  }

  // === Lazy Load == //
  let imageCount = 0
  const lazyLoadThreshold = 5

  function shouldLazyLoad(forceLazyLoad: boolean) {
    if (isPreview) return false
    if (forceLazyLoad) return true

    const index = imageCount

    imageCount++

    return index >= lazyLoadThreshold
  }

  // A method to format the source of any storyblok assets
  function formatSrc(src: string) {
    // Dev Mode should use original source so we don't have to be on the VPN
    if (!src || isDev()) return src
    // Staging will require the VPN
    return src.replace('https://a.storyblok.com', '')
  }

  interface assetFile {
    src: string | null
    width: number | undefined
    height: number | undefined
  }

  function getNormalizedAsset(file: assetFile | string, maxWidth: number, options: AssetOptions = {}): assetFile {
    const asset: assetFile = {
      src: null,
      width: undefined,
      height: undefined,
    }

    // Early Out if no file
    if (!file) return asset

    // JPGs come in as strings and SVG as an object - handle them accordingly
    if (typeof file === 'string') {
      asset.src = file
      const { width, height } = getAssetDimensionsFromUrl(file)
      asset.width = width
      asset.height = height
    } else {
      asset.src = file.src
      asset.width = file.width
      asset.height = file.height
    }

    if (options.width !== undefined && options.height !== undefined) {
      asset.width = options.width
      asset.height = options.height
    }

    // Early Out if we have bad data - aka missing anything
    if (!asset.src || !asset.width || !asset.height)
      return {
        src: null,
        width: undefined,
        height: undefined,
      }

    // Check scale ratio
    const ratio = maxWidth / asset.width
    const isTooSmall = ratio > 1 // do not upscale

    if (!isTooSmall) {
      asset.width = Math.floor(asset.width * ratio)
      asset.height = Math.floor(asset.height * ratio)
    }

    // JPGs come in as strings and SVG as an object - we only do this for jpg
    if (typeof file === 'string' && asset.width > 0 && asset.height > 0 && asset.src && asset.src.slice(-3) !== 'svg') {
      const fitIn = options.fillWithWhite ? 'fit-in/' : ''
      const fillColor = options.fillWithWhite ? '/filters:fill(FFF)' : ''
      asset.src = `${asset.src}/m/${fitIn}${asset.width}x${asset.height}${fillColor}`
    }

    asset.src = formatSrc(asset.src)

    return asset
  }

  // ==== Bridge Logic === //
  const bridgePromise = new Promise((resolve) => {
    // Do not do this server side
    if (import.meta.server || !isPreview.value) {
      resolve(undefined)
      return
    }

    const { addSrc, load, addCallback, addErrorCallback } = runScriptOnDemand()
    addSrc(`//app.storyblok.com/f/storyblok-v2-latest.js`)
    addCallback(() => {
      const storyblokInstance = new window.StoryblokBridge({
        preventClicks: true,
      })

      storyblokInstance.on('enterEditmode', () => {
        onNuxtReady(() => {
          storyblokState.value.isEditing = true
        })
      })

      storyblokInstance.on(['published', 'change'], () => {
        // reload page if save or publish is clicked
        window.location.reload()
      })

      resolve(storyblokInstance)
    })
    addErrorCallback(() => resolve(undefined))
    load()
  })

  async function addListener(callback: (data: Story) => void): Promise<undefined> {
    if (!isPreview.value) return

    const bridge = await bridgePromise
    if (!bridge) return

    bridge.on('input', (event: Story) => callback(event))
  }

  function bindStoryblokBridgeData(component: Record<string, any>) {
    const { _editable } = component || {}

    if (!_editable) return {}

    const blokOptions = JSON.parse(_editable.replace('<!--#storyblok#', '').replace('-->', ''))

    return {
      'data-blok-c': JSON.stringify(blokOptions),
      'data-blok-uid': blokOptions.id + '-' + blokOptions.uid,
    }
  }

  const sectionLightboxProvide = Symbol() as InjectionKey<{
    add: (src: string, width: number, height: number, caption?: string) => number
    open: (index: number) => void
  }>

  // TYPE GUARDS
  type StoryOf<T extends Story> = T['story']
  // CatalogStory
  function isCatalogStory(maybeCatalogStory: Story): maybeCatalogStory is CatalogStory {
    return isStoryOfCatalogStory(maybeCatalogStory.story)
  }
  function isStoryOfCatalogStory(
    maybeStoryOfCatalogStory: StoryOf<Story>
  ): maybeStoryOfCatalogStory is StoryOf<CatalogStory> {
    return maybeStoryOfCatalogStory.content.component === 'category-page'
  }

  // BrandStory
  function isBrandStory(maybeBrandStory: Story): maybeBrandStory is BrandStory {
    return isStoryOfBrandStory(maybeBrandStory.story)
  }
  function isStoryOfBrandStory(maybeStoryOfBrandStory: StoryOf<Story>): maybeStoryOfBrandStory is StoryOf<BrandStory> {
    return maybeStoryOfBrandStory.content.component === 'brand-page'
  }
  // CmsStory
  function isCmsStory(maybeCmsStory: Story): maybeCmsStory is CmsStory {
    return isStoryOfCmsStory(maybeCmsStory.story)
  }
  function isStoryOfCmsStory(maybeStoryOfCmsStory: StoryOf<Story>): maybeStoryOfCmsStory is StoryOf<CmsStory> {
    return maybeStoryOfCmsStory.content.component === 'cms-page'
  }
  // Global Content
  function isGlobalContentStory(maybeGlobalContentStory: Story): maybeGlobalContentStory is CmsStory {
    return isStoryOfGlobalContentStory(maybeGlobalContentStory.story)
  }
  function isStoryOfGlobalContentStory(
    maybeStoryOfGlobalContentStory: StoryOf<Story>
  ): maybeStoryOfGlobalContentStory is StoryOf<GlobalContentStory> {
    return maybeStoryOfGlobalContentStory.content.component === 'global-content'
  }

  // FooterStory
  function isFooterStory(maybeFooterStory: Story): maybeFooterStory is FooterStory {
    return isStoryOfFooterStory(maybeFooterStory.story)
  }
  function isStoryOfFooterStory(maybeStoryOfFooterStory: StoryOf<Story>): maybeStoryOfFooterStory is StoryOf<CmsStory> {
    return maybeStoryOfFooterStory.content.component === 'footer'
  }
  // HeaderStory
  function isHeaderStory(maybeHeaderStory: Story): maybeHeaderStory is HeaderStory {
    return isStoryOfHeaderStory(maybeHeaderStory.story)
  }
  function isStoryOfHeaderStory(maybeStoryOfHeaderStory: StoryOf<Story>): maybeStoryOfHeaderStory is StoryOf<CmsStory> {
    return maybeStoryOfHeaderStory.content.component === 'header'
  }

  function isNavbarStory(maybeNavbarStory: Story): maybeNavbarStory is NavbarStory {
    return isStoryOfNavbarStory(maybeNavbarStory.story)
  }
  function isStoryOfNavbarStory(maybeStoryOfNavbarStory: StoryOf<Story>): maybeStoryOfNavbarStory is StoryOf<CmsStory> {
    return maybeStoryOfNavbarStory.content.component === 'navbar'
  }

  return {
    provide: {
      storyblok: {
        initState,
        getPreviewStory,
        getPublishedAt,
        getFirstPublishedAt,
        getTags,
        getAuthors,
        getEditors,
        getNormalizedAsset,
        getFirstBgImageUrl,
        setFirstBgImageUrl,
        sectionLightboxProvide,
        isPreview,
        isEditing,
        contentType,
        isVisible,
        show,
        hide,
        shouldLazyLoad,
        formatSrc,
        addListener,
        bindStoryblokBridgeData,
        handleInheritedBool,
        isCmsStory,
        isFooterStory,
        isHeaderStory,
        isNavbarStory,
        isCatalogStory,
        isBrandStory,
        isGlobalContentStory,
      },
    },
  }
})

declare global {
  interface BaseStory<T> {
    story: {
      uuid: string
      published_at: string
      first_published_at: string
      content: T
      tag_list: string[]
    }
    rels: StoryOf<GlobalContentStory | AuthorStory | ProductCompareStory>[]
    requiresAuth?: boolean
  }

  type AuthorStory = BaseStory<{
    component: 'author'
    name: string
    _uid: string
    profilePicture: {
      filename: string
    }
  }>

  type BrandStory = BaseStory<{
    component: 'brand-page'
    topSections: Sections[]
    bottomSections: Sections[]
  }>

  type GlobalContentStory = BaseStory<{
    component: 'global-content'
    sections: Sections[]
    startDate: string
    endDate: string
  }>

  type CatalogStory = BaseStory<{
    component: 'category-page'
    metaTitle: string
    metaDescription: string
    metaDescriptionMake: string
    metaDescriptionYear: string
    metaDescriptionModel: string
    hideProducts: StringBoolean
    disableH1: StringBoolean
    facetValue2: string
    facetValue3: string
    facetName2: string
    facetName3: string
    facetValue: string
    facetName: string
    previewTitle: string
    previewDescription: string
    shareImage?: StoryblokAsset
    previewImage?: StoryblokAsset
    disableSeoIndex?: string
    password?: string
    topSections: Widget[]
    bottomSections: Widget[]
  }>

  type CmsStory = BaseStory<{
    component: 'cms-page'
    metaTitle: string
    metaDescription: string
    previewTitle: string
    previewDescription: string
    shareImage?: StoryblokAsset
    previewImage?: StoryblokAsset
    sections: Widget[]
    disableSeoIndex?: string
    authors?: Author[]
    editors?: Author[]
    password?: string
    hideHeaderFooter?: string
  }>

  // Header, Footer, Navbar are defined in the Sitewide plugin
  type HeaderStory = BaseStory<Header>
  type FooterStory = BaseStory<Footer>
  type NavbarStory = BaseStory<Navbar>

  interface Author {
    _uid: string
    component: 'author'
    name: string
    profileUrl: StoryblokLink
    profilePicture: StoryblokAsset
  }

  type Story =
    | AuthorStory
    | CmsStory
    | FooterStory
    | HeaderStory
    | NavbarStory
    | CatalogStory
    | BrandStory
    | GlobalContentStory

  type StringBoolean = 'INHERIT' | 'false' | 'true'
  type GapStrings = 'INHERIT' | 'NONE' | 'XSMALL' | 'SMALL' | 'MEDIUM' | 'LARGE' | 'XLARGE'

  type HorizontalStrings = 'INHERIT' | 'CENTER' | 'LEFT' | 'RIGHT'
  type VerticalStrings = 'INHERIT' | 'TOP' | 'MIDDLE' | 'BOTTOM'
  type AlignmentStrings =
    | 'INHERIT'
    | 'LEFT'
    | 'TOP'
    | 'CENTER'
    | 'MIDDLE'
    | 'RIGHT'
    | 'BOTTOM'
    | 'BETWEEN'
    | 'AROUND'
    | 'EVENLY'

  type SizeStrings = 'INHERIT' | 'NONE' | 'XSMALL' | 'SMALL' | 'MEDIUM' | 'LARGE'
  type SideSize = {
    top: SizeStrings
    left: SizeStrings
    right: SizeStrings
    bottom: SizeStrings
  }

  type StoryblokAsset = {
    alt: string
    filename: string
  }

  interface StoryblokLink {
    cached_url: string
    url: string
  }

  export type Sections = Section | GlobalContentSection

  interface Section extends Widget {
    component: 'section'
  }

  interface GlobalContentSection extends Widget {
    component: 'global-content-section'
    references: string[]
  }

  interface Widget {
    component: string
    id: string
    selector: string
    _uid: string
    mobileIsVisible: StringBoolean
    tabletIsVisible: StringBoolean
    desktopIsVisible: StringBoolean
    mobilePadding: SideSize
    tabletPadding: SideSize
    desktopPadding: SideSize
  }
  interface Widgets extends Widget {
    widgets: Widget[]
  }
  interface WidgetMaxWidth {
    mobileMaxWidth: string
    tabletMaxWidth: string
    desktopMaxWidth: string
  }

  interface WidgetMinHeight {
    mobileMinHeight: string
    tabletMinHeight: string
    desktopMinHeight: string
  }

  interface WidgetAlignment {
    mobileAlignment: HorizontalStrings
    tabletAlignment: HorizontalStrings
    desktopAlignment: HorizontalStrings
  }

  interface WidgetMargin {
    mobileMargin: SideSize
    tabletMargin: SideSize
    desktopMargin: SideSize
  }

  interface WidgetGutter {
    mobileGutter: GapStrings
    tabletGutter: GapStrings
    desktopGutter: GapStrings
  }

  interface SpaceLeft {
    desktop: number
    mobile: number
    tablet: number
  }
  interface AssetOptions {
    fillWithWhite?: boolean
    width?: number
    height?: number
  }
}

export default defineNuxtPlugin(() => {
  const { isIntegrationEnabled } = useUtils()
  const { $constructor } = useNuxtApp()

  if (
    !isIntegrationEnabled('constructor') ||
    !isIntegrationEnabled('constructorTest') ||
    !isIntegrationEnabled('speedcurve')
  )
    return

  window.LUX.addData('isConstructor', $constructor.test.showConstructor ? 'Yes' : 'No')
})


import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "buildId": "c2527e5f02efcc1917c791762d6f0738e2a49e26",
  "nuxt": {
    "buildId": "f53cb99d-4fb5-4659-9bb8-c9935757dfc5"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}



export default /*@__PURE__*/ defuFn(inlineConfig)

import ConstructorClient from '@constructor-io/constructorio-client-javascript'

export default defineNuxtPlugin(() => {
  const { $sitewideConfig, $uiEvents } = useNuxtApp()
  const runtimeConfig = useRuntimeConfig()
  const { isIntegrationEnabled } = useUtils()

  if (!isIntegrationEnabled('constructor')) return

  // Constructor Beacon
  onNuxtReady(() => {
    const scriptOnDemand = runScriptOnDemand()
    scriptOnDemand.addSrc('https://cnstrc.com/js/cust/realtruck_amZrnR.js')
    scriptOnDemand.load()
  })

  const apiKey: string = $sitewideConfig.config.constructorIndexKey

  // If the constructor test is disabled then we don't need to make a constructor client
  if (!isIntegrationEnabled('constructorTest') || !apiKey) return

  // Create Constructor Client
  const client = new ConstructorClient({
    apiKey,
    serviceUrl: runtimeConfig.public.constructorServiceUrl,
    sendTrackingEvents: true,
  })

  // We we get the orderPlaced event, trigger the purchase event
  $uiEvents.$on('orderPlaced', (receiptObj: Receipt) => {
    client.tracker.trackPurchase({
      orderId: receiptObj.orderId,
      revenue: receiptObj.summary.cartSubtotal / 100,
      items: receiptObj.items.map((item) => ({
        itemId: item.productLineSlug,
        variationId: `${item.productLineSlug}_${item.productSlug}`,
        price: item.salePrice / 100,
        count: item.qty,
      })),
    })
  })

  // Get ab test data from server so we can provide it to the client
  const testData = useState('constructorTestData')

  return {
    provide: {
      constructor: {
        client,
        test: testData.value,
      },
    },
  }
})

export default function wireProductListeners() {
  const segment = useSegmentHelper()
  const { formatCartProduct, formatProductData, getCategoryTreeFromCrumbs, getFitment, isQuickship } =
    useSegmentProductsHelper()
  const { $uiEvents } = useNuxtApp()
  const rootStore = useRootStore()
  const cartStore = useCartStore()

  $uiEvents.$on('productViewed', emitProductViewed)
  $uiEvents.$on('productViewed', emitProductShipping)
  $uiEvents.$on('addToCart', emitAddToCart)
  $uiEvents.$on('removeFromCart', emitRemoveFromCart)

  $uiEvents.$on('backInStockSubscribed', emitBackInStockSubscribed)

  $uiEvents.$on('productListClicked', emitProductListClicked)
  $uiEvents.$on('productListViewed', emitProductListViewed)
  $uiEvents.$on('productListFiltered', emitProductListFiltered)

  async function emitAddToCart() {
    if (!cartStore?.lastItem) return
    // Get the Last Item added to Cart
    const item = cartStore.lastItem
    // Format the data
    const product = formatCartProduct(item)

    // Tell Segment
    segment.track('Product Added', {
      cart_id: rootStore.permId,
      ...product,
    })
  }

  function emitRemoveFromCart(item: any) {
    if (!item) return
    const product = formatCartProduct(item)
    segment.track('Product Removed', { cart_id: rootStore.permId, ...product })
  }

  async function emitProductViewed(product: any) {
    if (!product) return

    const viewedProductObj = formatProductData(product)

    segment.track('Product Viewed', {
      ...viewedProductObj,
    })
  }

  function emitProductShipping(product: any) {
    if (!product.shipping) return

    const sameDayObj = getTimeRemainingText(product.shipping)
    const madeToOrderText = getMadeToOrderText(product.isMadeToOrder)
    const product_category = getCategoryTreeFromCrumbs(product.crumbs)

    segment.track('Customer Shipping Expectations', {
      ...(product.sku && { sku: product.sku, label: product.sku }),
      product: product.name,
      ...(product.actualPrice && { price: product.actualPrice.min / 100 }),
      brand: product.brand.name,
      ...(product_category && { product_category: product_category.slugs.join('/') }),
      ...(product.shipping.sameDay && { shipping_same_day_object: sameDayObj }),
      ...(product.shipping.sameDay && sameDayObj.orderText && { shipping_same_day_message: sameDayObj.orderText }),
      ...(madeToOrderText && { made_to_order: madeToOrderText }),
      // shipping text only shows for products that are not sameDay
      // so we need to make sure we send the proper value here because of it.
      ...(!product.shipping.sameDay &&
        product.shipping.isEligibleFreeShipping && {
          shipping_text: getFreeShippingText(product.shipping),
        }),
      category: 'Customer Messages',
      action: 'Customer Shipping Expectations',
    })
  }

  function emitBackInStockSubscribed({ skuSlug, email }) {
    const primaryFitment = getFitment()

    segment.track('Back In Stock', {
      skuSlug,
      email,
      primaryFitment,
    })
  }

  function emitProductListClicked({ algoliaObj, position, indexName, queryID }: any) {
    const productObj = getAlgoliaProduct(algoliaObj, position + 1)
    segment.track('Product Clicked', {
      ...(indexName && { index: indexName }),
      ...(queryID && { queryID }),
      ...productObj,
    })
  }

  // when a product square is viewed (currently just the whole result, not on viewport)
  function emitProductListViewed({ results, indexName, queryID }: any) {
    segment.track('Product List Viewed', {
      list_id: 'main',
      ...(indexName && { index: indexName }),
      ...(queryID && { queryID }),
      // category - We get individual products, but no main category to put here
      products: getAlgoliaProducts(results),
    })
  }

  function emitProductListFiltered({ filters, sort, products }: any) {
    // Determine Filters
    const filterObj = Object.keys(filters).reduce((acc, attributeName) => {
      const values = filters[attributeName].map((attribute: any) => ({
        type: attributeName,
        value: attribute.value,
      }))
      acc.push(...values)
      return acc
    }, [])

    // Determine Sort from indexName
    let sortType = null
    let sortDirection = null
    if (sort) {
      sortType = sort.includes('price') ? 'price' : 'recommended'
      if (sort.includes('_desc')) sortDirection = 'desc'
      else if (sort.includes('_asc')) sortDirection = 'asc'
    }

    segment.track('Product List Filtered', {
      list_id: 'main',
      filters: filterObj,
      ...(sort && {
        sorts: [
          {
            type: sortType,
            value: sortDirection,
          },
        ],
      }),
      products: getAlgoliaProducts(products),
    })
  }

  function getAlgoliaProducts(algoliaObjs: any, useProductLineSlug = false) {
    return algoliaObjs.map((product, index) => {
      return getAlgoliaProduct(product, index + 1, useProductLineSlug)
    })
  }

  function getAlgoliaProduct(product: any, position = 1, useProductLineSlug = false) {
    const isQuickshipable = isQuickship(product)
    const primaryFitment = getFitment()

    return {
      objectID: product.objectID,
      product_id: useProductLineSlug ? product.productLineSlug : product.productId,
      sku: product.skuSlug,
      category: getCategoryTreeSlugFromAlgolia(product.categories),
      name: product.productLineName,
      brand: product.brandSlug,
      price: product.salePrice / 100,
      quantity: 1,
      position,
      ...(isQuickshipable !== null && { quickship: isQuickshipable }),
      ...(primaryFitment && primaryFitment),
    }
  }

  // since cats are super weird in Algolia, this finds the primary and constructs the right string
  function getCategoryTreeSlugFromAlgolia(algoliaCategories: any) {
    // find the cat obj that's primary
    const catObj = algoliaCategories.find((catObj) => {
      // eslint-disable-next-line eqeqeq
      return catObj.primary == true
    })

    // shouldn't happen
    if (catObj === undefined) return undefined

    // get last cat level since it has the full breadcrumb
    let lastLevel = 0

    for (let i = 4; i >= 0; i--) {
      if (catObj[`lvl${i}`]) {
        lastLevel = i
        break
      }
    }

    return catObj[`lvl${lastLevel}`].slug.split('|').join('/')
  }
}

<template lang="pug">
div(:class='{ relative: $storyblok.isEditing.value }')
  div(class='relative z-30 flex [&_form]:grow')
    BaseForm(data-cnstrc-search-form @submit='submitSearch')
      BaseFormInput(
        id='searchBar'
        v-model='searchQuery'
        data-cnstrc-search-input
        name='searchBar'
        :placeholder='searchPlaceholder'
        autocomplete='off'
        aria-label='Search'
        data-testid='searchBar'
        :classes='{ outer: "$reset mb-0", inner: "$reset mb-0", input: "w-full h-[36px] bg-white rounded-l rounded-r-none px-3 text placeholder:text text-sm lg:text-xs xl:text-sm" }'
        @keyup.esc='resetSearch()'
      )
      span(
        v-if='searchQuery.length > 0'
        class='absolute top-0 right-10 h-[36px] w-10 inline-flex items-center justify-center text-right pointer-events-auto z-0'
      )
        CloseIcon(class='w-3 h-3' @click='resetSearch()')

    BaseButton(
      data-cnstrc-search-submit-btn
      class='inline-flex h-[36px] w-10 p-0'
      :is-inline='true'
      color='PRIMARY'
      :has-custom-padding='true'
      @click='submitSearch'
    )
      SearchIcon(class='w-4 h-4 [&_path]:fill-black')

    CmsWidgetSiteSearchAlgoliaResults(
      :show-categories-in-search='showCategoriesInSearch'
      :show-brands-in-search='showBrandsInSearch'
      :search-query='searchQuery'
      @close='resetSearch()'
    )

  //- This is a transparent shade to catch the clicks off of the search bar
  div(v-if='searchQuery' class='fixed top-0 right-0 bottom-0 left-0 z-20' @click='resetSearch()')

  //- Shade for storyblok editor to prevent clicks
  div(v-if='$storyblok.isEditing.value' class='absolute inset-0 z-50')
</template>

<script setup lang="ts">
import SearchIcon from '@/assets/search.svg?component'
import CloseIcon from '@/assets/x.svg?component'

interface SiteSearchWidget extends Widget {
  searchText: string
  showCategoriesInSearch: boolean
  showBrandsInSearch: boolean
  searchRedirectsEnabled: boolean
}

const { data } = defineProps<{
  data: SiteSearchWidget
}>()

const { $algolia, $sitewideConfig, $uiEvents } = useNuxtApp()
const routeBuilder = useRouteBuilder()
const fitmentStore = useFitmentStore()
const error = useError()

const searchQuery = ref('')

const searchBar = ref(null)
onMounted(() => {
  // We need to get the address input by ID for focus  because FormKit does not pass $refs down to the input element
  searchBar.value = document.getElementById('searchBar')
})

const fitmentFormatted = computed(() => {
  return fitmentStore.fitmentFormatted
})

const searchPlaceholder = computed(() => {
  if (!fitmentFormatted.value) return data?.searchText
  return `Search ${fitmentFormatted.value} Parts`
})

const showCategoriesInSearch = computed(() => {
  return data?.showCategoriesInSearch
})

const showBrandsInSearch = computed(() => {
  return data?.showBrandsInSearch
})

function emitSearched() {
  if (!searchQuery.value) return
  $uiEvents.$emit('searched', { query: searchQuery.value })
}

watchDebounced(
  searchQuery,
  () => {
    emitSearched()
  },
  { debounce: 500 }
)

async function submitSearch() {
  if (!searchQuery.value) return

  emitSearched()

  if (data?.searchRedirectsEnabled) {
    // Check for a Redirect attached to this search query
    const { results } = await $algolia.searchContexts.searchRedirect.setQuery(`"${searchQuery.value.trim()}"`)._search()

    const [result] = results

    // If we find a Redirect match for the Query we need to push them to that
    if (result && result.terms.includes(searchQuery.value.trim().toLowerCase())) {
      // Handle External Link
      if (result.url.startsWith('http') && !result.url.startsWith(`https://${$sitewideConfig.domain}`)) {
        window.location = result.url
        return
      }

      // Handle Internal Link
      await navigateTo(routeBuilder.formatRoute(result.url.replace(`https://${$sitewideConfig.domain}`, '')))

      resetSearch()
      return
    }
  }

  await navigateTo({ name: 'search', query: { q: searchQuery.value.trim() } })
  resetSearch()
}

function resetSearch() {
  searchQuery.value = ''
  if (searchBar.value) searchBar.value.focus()
}
</script>

<template lang="pug">
FormKit(
  v-bind='$attrs'
  data-role='none'
  mask='(###) ###-####'
  type='mask'
  unmask-value='true'
  :aria-label='label'
  :classes='classes'
  :errors='errors'
  :label='label'
  :name='name'
  :validation='validation'
  :validation-label='validationLabel'
  @blur='onBlur'
  @focus='onFocus'
)
</template>

<script setup lang="ts">
const emit = defineEmits(['blur', 'focus'])
/*
  ===
  FormKit Properties
  ===
  classes: Object
    The classes prop is similar to the section-key class prop except it allows setting classes on all
    sections at the same time.
    See: https://formkit.com/essentials/styling#classes-prop#classes-prop
  ---
  errors: String[]
    Array of strings to show as error messages on this field.
    See: https://formkit.com/essentials/inputs#explicit-errors
  ---
  validation: String
    The validation rules to be applied to the input.
    See: https://formkit.com/essentials/validation
  ---
  validation-label: String
    Determines what label to use in validation error messages, by default it uses the label prop if
    available, otherwise it uses the name prop.
    See: https://formkit.com/essentials/validation#custom-messages
*/

interface Props {
  classes?: {}
  errors?: string[]
  label?: string
  name?: string
  validation?: string
  validationLabel?: string
}

withDefaults(defineProps<Props>(), {
  classes: () => {
    return {}
  },
  errors: () => [],
  label: '',
  name: '',
  validation: '',
  validationLabel: '',
})

function onBlur() {
  emit('blur')
}

function onFocus() {
  emit('focus')
}
</script>
